require('./termintafel.css');
var Keycloak = require('keycloak-js');
var environment = require('./environments/environment');

var vppfad = '';
var rolle = '';
var teamart = 'buero';
var monat = '';
var jahr = '';
var callbackdata = [];
var pinid = null;
var loggedinAtMonth = '';
var keycloak = null;

function init() {
    keycloak = new Keycloak(environment.keycloak);
    keycloak.init({onLoad: 'login-required', pkceMethod: 'S256'})
        .then(authenticated => jsonp(0))
        .catch(err => console.error('failed to initialize: ', err));
}

function jsonp(n, el) {

    /*##### path devapi #####*/
    //var path = 'https://www.titsystembackend.de/termintafelApi/0.1/';
    var path = '/termintafel/api/';

    var url = [];
    keycloak.updateToken(30)
        .then(function () {
            var urlx = [
                /*0*/
                path + 'coreVp?access_token=' + keycloak.token + '&callback=termintafel.pfad',
                /*1*/
                path + 'calendar?datum=today' + '&access_token=' + keycloak.token + '&callback=termintafel.datum',
                /*2*/
                path + 'buero?access_token=' + keycloak.token + '&monat=' + jahr + '-' + monat + '&teamart=' + teamart + '&callback=termintafel.buero',
                /*3*/
                path + 'termine?access_token=' + keycloak.token + '&monat=' + jahr + '-' + monat + '&teamart=' + teamart + '&callback=termintafel.termine',
                /*4*/
                path + 'calendar?datum=01.' + monat + "." + jahr + '&access_token=' + keycloak.token + '&callback=termintafel.datum2',
            ];


            switch (n) {
                case 0:
                    url = [urlx[0]];
                    break;

                case 1:
                    url = [urlx[1]];
                    break;

                case 2:
                    url = [urlx[2], urlx[3]];
                    break;

                case 3:
                    url = [urlx[4]];
                    break;

                default:
                    var dataset = n;
                    var urlvar = '';

                    if (dataset.taktualisiert == 1) {
                        urlvar = 'termine/update';
                    } else {
                        urlvar = 'termine/create';
                    }

                    url = [
                        path + urlvar +
                        '?vppfad=' + dataset.vppfad +
                        '&tid=' + dataset.tid +
                        '&intnachname=' + dataset.intnachname +
                        '&intvorname=' + dataset.intvorname +
                        '&tumsatzschaetzung=' + dataset.tumsatzschaetzung +
                        '&tnotiz=' + dataset.tnotiz +
                        '&tdatum=' + dataset.tdatum +
                        '&ttyp=' + dataset.ttyp +
                        '&tausgefallen=' + dataset.tausgefallen +
                        '&access_token=' + keycloak.token +
                        '&callback=termintafel.termineintragen'
                    ];

                    // push do sth.
                    // sendMessage(dataset);

                    break;
            }

            for (var i = 0; i < url.length; i++) {
                script = document.createElement("script");
                script.setAttribute("src", url[i]);
                script.setAttribute("type", "application/javascript");
                document.getElementsByTagName("head")[0].appendChild(script);

                //falls das Laden des scripts fehlschlägt gibt es eine Fehlermeldung!!!!
                script.onerror = function () {
                    alert("Der Anwendungsserver antwortet nicht. Bitte nochmal versuchen.");
                    //window.location = "https://login.taures.de/";
                };
            }
        })
        .catch(function () {
            alert("Der Anwendungsserver antwortet nicht. Bitte nochmal versuchen.");
        })
}

/*##### do sth. with data from server #####*/

function pfad(data) {
    document.getElementById("loadingmsg").innerHTML += 'VPPFAD: ' + data.pfad + " Rolle: " + data.rolle + '<br />';
    console.log("OK! --> VPPFAD: " + data.pfad + " Rolle: " + data.rolle);
    //console.log(data.pfad);
    vppfad = data.pfad;
    rolle = data.rolle;
    removeScript();
    jsonp(1);
}

function datum(data) {
    document.getElementById("loadingmsg").innerHTML += 'DATUM: ' + data.Monat + "/" + data.Jahr + '<br />';
    console.log("OK! --> DATUM: " + data.Monat + "/" + data.Jahr);
    callbackdata[1] = data;
    //console.log(callbackdata[1]);
    loggedinAtMonth = data.Monat;
    monat = data.Monat;
    jahr = data.Jahr;
    jsonp(2);
}

function datum2(data) {
    document.getElementById("loadingmsg").innerHTML = '';
    document.getElementById("loadingmsg").innerHTML += 'DATUM: ' + data.Monat + "/" + data.Jahr + '<br />';
    callbackdata[1] = data;
    //console.log(callbackdata[1]);
    jsonp(2);
}

function buero(data) {
    document.getElementById("loadingmsg").innerHTML += 'BÜRO: ' + data.buero.name + '<br />';
    console.log("OK! --> BUERO: " + data.buero.name);
    callbackdata[0] = data;
    //console.log(callbackdata[0]);
    checkdata();
}

function termine(data) {
    document.getElementById("loadingmsg").innerHTML += 'TERMINE<br />';
    console.log("OK! --> TERMINE:");
    callbackdata[2] = data;
    //console.log(callbackdata[2]);
    checkdata();
}

function checkdata() {
    if (typeof (callbackdata[0]) === "object" && typeof (callbackdata[2]) === "object") {
        // # admin yes/no
        if (rolle == 'TL' || rolle == 'VM' && teamart != 'gesamt') {
            if (rolle == 'TL') {
                var lsr = 'Teamleiter';
                var lsb = 'Dein Team';
            } else if (rolle == 'VM') {
                var lsr = 'Vertriebsmanager';
                var lsb = 'das Büro';
            }
            var ls = document.getElementById("loading");
            var lsp = document.createElement('p');
            lsp.setAttribute('id', 'asyn');
            lsp.innerHTML =
                '<br /><span style="color:green;">Als ' + lsr +
                ' kannst Du ' + lsb + ' administrieren. Möchtest Du das?</span><br/><br/><input type="button" class="msg" value="Ja" onclick="termintafel.adminYesNo(value)">' +
                '<input type="button" class="msg" value="Nein" onclick="termintafel.adminYesNo(value)">';

            ls.appendChild(lsp);

        } else {
            generatetable();
        }
        // # admin yes/no end
        //generatetable();

    }
    removeScript();
}

function termineintragen(data) {
    console.log(data);
    if (data.status == "transaction failed") {
        alert("Bitte Anwendung schließen und neu einloggen! Terminänderung wurde nicht gespeichert.");
    }
    if (data.status == "transaction failed" || data.status == "transaction commited") {
        document.getElementById('chatlog').value = data.status;
        removeScript();
    }
}

function removeScript() {
    var scripts = document.getElementsByTagName("script");
    for (var i = 0; i < scripts.length; i++) {
        var url = scripts[i].getAttribute("src");
        if (!url) continue;
        if (url.indexOf("callback") >= 0) {
            scripts[i].parentNode.removeChild(scripts[i]);
        }
    }
}

/*##### switch date #####*/

function datumaendern() {
    document.getElementById("loading").style.display = '';
    var m = document.getElementById('m');
    var y = document.getElementById('y');
    monat = m.options[m.selectedIndex].value;
    jahr = y.options[y.selectedIndex].value;
    var table = document.getElementById('tt');
    tt.innerHTML = '';
    callbackdata = [];
    jsonp(3);
}

/*##### switch view #####*/

function switchview(n) {
    document.getElementById("loading").style.display = '';

    switch (n) {
        case 0:
            teamart = 'buero';
            break;
        case 1:
            teamart = 'team';
            break;
        case 2:
            teamart = 'gesamt';
            break;
        default:
    }
    datumaendern();
}

/*##### build table #####*/

function generatetable() {
    //buerodaten
    var bd = callbackdata[0];
    //datum
    var dt = callbackdata[1];
    var ersterwochentag = parseInt(dt.ErsterWochentag);
    var aktuellertag = parseInt(dt.aktuellerTag);
    monat = parseInt(dt.Monat);
    var tageimmonat = parseInt(dt.TageImMonat);
    jahr = parseInt(dt.Jahr);
    var wochentag = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
    //table
    var table = document.getElementById('tt');
    //caption
    var caption = document.createElement('caption');
    table.appendChild(caption);
    //thead
    var thead = document.createElement('thead');
    table.appendChild(thead);
    //tfoot
    var tfoot = document.createElement('tfoot');
    table.appendChild(tfoot);
    //thead calendar
    var row = document.createElement('tr');
    //month switch
    for (var i = 1; i < tageimmonat + 1; i++) {
        if (i == 1) {
            cell = document.createElement('th');
            cell.innerHTML = '<p id="ym"><select id="m">' +
                '<option value=' + monat +
                '>' + monat +
                '</option>' +
                '<option value="1">1</option>' +
                '<option value="2">2</option>' +
                '<option value="3">3</option>' +
                '<option value="4">4</option>' +
                '<option value="5">5</option>' +
                '<option value="6">6</option>' +
                '<option value="7">7</option>' +
                '<option value="8">8</option>' +
                '<option value="9">9</option>' +
                '<option value="10">10</option>' +
                '<option value="11">11</option>' +
                '<option value="12">12</option>' +
                '</select>' +
                ' -' +
                '<select id="y">' +
                '<option value=' + jahr +
                '>' + jahr +
                '</option>' +
                '<option value="2016">2016</option>' +
                '<option value="2017">2017</option>' +
                '<option value="2018">2018</option>' +
                '<option value="2019">2019</option>' +
                '<option value="2020">2020</option>' +
                '<option value="2021">2021</option>' +
                '<option value="2022">2022</option>' +
                '<option value="2023">2023</option>' +
                '<option value="2024">2024</option>' +
                '</select><button onclick="termintafel.datumaendern()" id="ymb" type="button">Daten laden!</button></p>';
            row.appendChild(cell);
        }
        var cell = document.createElement('td');
        cell.innerHTML = '<p>' + wochentag[ersterwochentag++] + '<p>' + i;
        if (i == aktuellertag && monat == loggedinAtMonth) {
            cell.className = 'current';
        }
        if (ersterwochentag == 1) {
            if (i == aktuellertag && monat == loggedinAtMonth) {
                cell.className = 'sunday current';
            } else {
                cell.className = 'sunday';
            }
        }
        if (ersterwochentag == 7) {
            if (i == aktuellertag && monat == loggedinAtMonth) {
                cell.className = 'saturday current';
            } else {
                cell.className = 'saturday';
            }
            ersterwochentag = 0;
        }
        row.appendChild(cell);
    }
    thead.appendChild(row);
    var clone = row.cloneNode(true);
    tfoot.appendChild(clone);
    tfoot.firstChild.firstChild.innerHTML = '';
    var matchingteam = '';
    var spanclass = '';
    //vp/tl @ team
    var vxinteam = '';

    for (var i = 0; i < bd.teams.length; i++) {
        for (var j = 0; j < bd.teams[i].length; j++) {
            if (bd.teams[i][j].vppfad == vppfad) {
                matchingteam = i;
                vxinteam = bd.teams[i][0].vpbereich;
            }
        }
    }
    //console.log(rolle, " in Team ",vxinteam);
    //tbody team header
    for (var i = 0; i < bd.teams.length; i++) {
        var tbodyperf = document.createElement('tbody');
        tbodyperf.className = 'teamhead';
        tbodyperf.id = 'team' + i + 'head';
        table.appendChild(tbodyperf);
        if (i == matchingteam) {
            spanclass = 'activeteam';
            var imgsrc = 'min';
        } else {
            spanclass = 'inactiveteam';
            imgsrc = 'max';
        }
        var colspan = tageimmonat;
        var teamid = 'team' + i;
        var tbodyperfTr = document.createElement('tr');
        tbodyperf.appendChild(tbodyperfTr);
        tbodyperfTh = document.createElement('th');
        tbodyperfTh.innerHTML =
            '<p class=\'' + spanclass +
            '\'><input id=\'img' + teamid +
            '\' type=\'image\' alt=\'minmax\' title=\'show/hide team\' class=\'expand' +
            '\' src=\'images/' + imgsrc +
            '.png\' onclick=\'termintafel.hideteam(' + teamid +
            ')\'>T-' + bd.teams[i][0].vpbereich +
            ' (' + bd.teams[i][0].bereichsleiter.replace(' ', '&nbsp;') + ')' +
            '</p>';
        tbodyperfTr.appendChild(tbodyperfTh);
        var tbodyperfTd = document.createElement('td');
        tbodyperfTd.setAttribute("colspan", colspan);
        tbodyperfTd.innerHTML = '<input type=\'image\' alt=\'stat\' title=\'show/hide statistics\' class=\'expand\' src=\'images/stat.png\' onclick=\'termintafel.hidestatistics();\'><ul class=\'chartsteam\' id=\'charts' + teamid + '\'></ul><ul class=\'statisticsteam\' id=\'statistics' + teamid + '\'></ul>';
        tbodyperfTr.appendChild(tbodyperfTd);
        //tbody team
        var tbody = document.createElement('tbody');
        //
        if (i == matchingteam) {
        } else {
            tbody.setAttribute('style', "display:none");
        }
        //id for team
        tbody.id = 'team' + i;
        tbody.setAttribute('data-team', bd.teams[i][0].vpbereich);
        table.appendChild(tbody);

        // # statistics counter if skipped (not visible) vp
        var a = 0;

        for (var j = 0; j < bd.teams[i].length; j++) {

            //row for every teammember in a team
            var row = document.createElement('tr');
            row.id = bd.teams[i][j].vppfad;
            //row.setAttribute('data-team', bd.teams[i][0].vpbereich);

            //thead for every teammember row in a team
            var thead = document.createElement('th');
            //firefox rel./abs. issue
            thead.setAttribute('class', 'th');

            // # admin functions start -->
            var n = parseInt(bd.teams[i][j].vpsichtbar);

            // # vp not visible || vp in other teams not visible
            if (n == 0 && rolle == 'VP' || n == 0 && rolle == 'TL' && matchingteam != i) {
                a++;
                continue;
            }
            // cell dropzones ev switch
            if (row.id == vppfad || rolle == 'TL' || rolle == 'VM') {
                row.addEventListener('dragleave', function (event) {
                    dragleave(event)
                }, false);
                row.addEventListener('dragenter', function (event) {
                    dragenter(event)
                }, false);
                row.addEventListener('dragover', function (event) {
                    allowDrop(event)
                }, false);
                row.addEventListener('drop', function (event) {
                    drop(event)
                }, false);
                row.addEventListener('click', evSwitch, false);
                //console.log(rolle, " in Reihe ", i);
            }

            if (rolle == 'TL' || rolle == 'VM') {

                var g = bd.teams[i][j].vppfad;
                var adid = ('ad' + i + j);

                switch (n) {
                    case 0:
                        if (rolle == 'TL' && matchingteam == i || rolle == 'VM') {
                            row.setAttribute('style', "opacity:.5;");
                        }
                        break;
                    default:
                }
            }

            // <-- # admin functions end

            //logged in
            if (row.id == vppfad) {
                if (rolle == 'VM') {
                    var svgb = '<button id="svg" class="link" onclick="termintafel.switchview(2)">Gesamt</button>' + ' /';
                    var beta = '';
                } else {
                    svgb = '';
                    beta = '';
                }
                caption.innerHTML +=
                    '<p><span>' + bd.buero.name +
                    '</span><span>' + rolle + ' ' + bd.teams[i][j].vpvorname +
                    ' ' + bd.teams[i][j].vpnachname +
                    ' (' + svgb +
                    '<button id="svb" onclick="termintafel.switchview(0)">Büro</button>'
                    + '/' +
                    '<button id="svt" onclick="termintafel.switchview(1)">Team</button>' +
                    ')' +
                    '</span></p>' +
                    '<ul class="nav"><li><a class="active_nav" href="termintafel.html"><img src="images/tt.png" alt="tt" height="25" width="25"></a></li>' +
                    '<li><a href="termintafel_taures_bueros.html"><img src="images/tb.png" alt="tb" height="25" width="25"></a></li>' +
                    '<li><a href="/termintafel/api/statistics/statistics?access_token=' + keycloak.token + '" target="_blank"><img src="images/ttbs.png" alt="ttbs" height="25" width="25"></a></li>'
                    + beta + '</ul>';

                thead.innerHTML =
                    '<p style="color:green;" class="nn">' + bd.teams[i][j].vpvorname[0] + '. ' + bd.teams[i][j].vpnachname +
                    '</p>' +
                    '<ul class="statisticsvp" id=statisticsteam' + i +
                    'vp' + (j - a) +
                    '></ul>';
                //not logged in
            } else {
                thead.innerHTML +=
                    '<p class="nn">' + bd.teams[i][j].vpvorname[0] + '. ' + bd.teams[i][j].vpnachname +
                    '</p>' +
                    '<ul class="statisticsvp" id=statisticsteam' + i +
                    'vp' + (j - a) +
                    '></ul>';
            }

            row.appendChild(thead);

            // cells in a row for every teammember
            ersterwochentag = parseInt(dt.ErsterWochentag);
            for (var k = 1; k < tageimmonat + 1; k++) {
                var cell = document.createElement('td');
                cell.setAttribute('data-date', jahr + '-' + monat + '-' + k);
                ersterwochentag++;
                if (k == aktuellertag && monat == loggedinAtMonth) {
                    cell.className = 'current';
                }
                if (ersterwochentag == 1) {
                    if (k == aktuellertag && monat == loggedinAtMonth) {
                        cell.className = 'sundaydot current';
                    } else {
                        cell.className = 'sundaydot';
                    }
                }
                if (ersterwochentag == 7) {
                    ersterwochentag = 0;
                }
                row.appendChild(cell);
            }
            tbody.appendChild(row);
        }
    }

    if (teamart == 'buero') {
        document.getElementById('svb').setAttribute('class', 'link active');
        document.getElementById('svt').setAttribute('class', 'link');
    }
    if (teamart == 'team') {
        document.getElementById('svt').setAttribute('class', 'link active');
        document.getElementById('svb').setAttribute('class', 'link');
    }
    if (teamart == 'gesamt') {
        document.getElementById('svg').setAttribute('class', 'link active');
        document.getElementById('svb').setAttribute('class', 'link');
        document.getElementById('svt').setAttribute('class', 'link');
    }

    //example pin generator --> cellarama();

    //generate pins
    generatepins();

    //eventListener on click (small pins)
    //addEventListener();

    //add termine
    terminE(vxinteam);

    console.log('Termintafel generiert!');
    document.getElementById("loading").style.display = 'none';

}

/*##### admin yes/no  #####*/
function adminYesNo(string) {
    //console.log(string);
    if (string == 'Ja') {

    } else {
        rolle = 'VP';
    }
    generatetable();
    var el = document.getElementById('asyn');
    el.parentNode.removeChild(el);
}

/*##### add termine  #####*/

function terminE(e) {
    var td = callbackdata[2];

    for (var i = 0; i < td.length; i++) {
        var vppfad = td[i].vppfad
        var row = document.getElementById(vppfad);

        if (row == null) continue;
        console.log("Pfad: " + vppfad + " (" + document.getElementById(vppfad).firstChild.childNodes[0].innerHTML + ', ' + document.getElementById(vppfad).firstChild.childNodes[1].innerHTML + ") hat " + td[i].termine.length + " Termine");
        //console.log(document.getElementById(td[i].vppfad).dataset.team);
        var tlteam = document.getElementById(vppfad).parentNode.dataset.team;

        row.addEventListener('mouseover', evSwitch, false);
        row.addEventListener('mouseout', evSwitch, false);

        if (td[i].termine.length > 0) {
            var cells = row.getElementsByTagName('td');

            for (var j = 0; j < td[i].termine.length; j++) {
                //console.log("- Termin " + j + ": " + td[i].termine[j].tdatum + " " + td[i].termine[j].ttyp);
                //console.log("rowindex: "+document.getElementById(td[i].pfad).rowIndex);

                for (var k = 0; k < cells.length; k++) {
                    if (cells[k].dataset.date == td[i].termine[j].tdatum) {
                        if (td[i].vppfad == vppfad || rolle == 'VM' || rolle == 'TL' && e == tlteam) {
                            access = " access";
                        } else {
                            access = " small";
                        }
                        var span = document.createElement('span');
                        span.setAttribute('id', td[i].termine[j].tid);
                        span.setAttribute('class', 'circle ' + td[i].termine[j].ttyp + access);
                        span.setAttribute('data-ttyp', td[i].termine[j].ttyp);
                        span.setAttribute('data-intnachname', td[i].termine[j].intnachname);
                        span.setAttribute('data-tausgefallen', td[i].termine[j].tausgefallen);
                        span.setAttribute('data-tnotiz', td[i].termine[j].tnotiz);
                        span.setAttribute('data-tumsatzschaetzung', td[i].termine[j].tumsatzschaetzung);
                        span.setAttribute('data-intvorname', td[i].termine[j].intvorname);
                        cells[k].appendChild(span);
                    }
                }
            }
        }
    }

    // > 6 Termine
    var cells = document.getElementsByTagName('td');
    for (var i = 0; i < cells.length; i++) {

        if (cells[i].childNodes.length > 6) {
            //console.log(cells[i].parentNode.firstChild.childNodes[1].innerHTML, ' hat am ', cells[i].dataset.date, ' ', cells[i].childNodes.length, ' Termine.');
            for (var j = 0; j < cells[i].childNodes.length; j++) {
                //var cla = cells[i].childNodes[j].getAttribute('class');
                //cells[i].childNodes[j].setAttribute('class', cla + ' smaller');
                cells[i].childNodes[j].setAttribute('data-smaller', 'j');
            }

        }
    }

    countdata();
    callbackdata = [];
    document.getElementById("loadingmsg").innerHTML += 'OK!';
}

/*##### delay sth.  #####*/

/*
function delay(ms) {
ms += new Date().getTime();
while (new Date() < ms){}
}
*/

/*##### event listener clickable pins  #####*/

function addEventListener() {
    document.getElementById(vppfad).addEventListener('click', evSwitch, false);
}

function removeEventListener() {
    document.getElementById(vppfad).removeEventListener('click', evSwitch, false);
}

//event delegation
/*
function clickedPin(e){
	if (e.target && e.target.nodeName == 'SPAN'){
            removerowmessage();
            pinid = e.target.id;
            console.log('PIN CLICKED ID --> ', pinid);
            openmessage(2, pinid);
			//console.log(e.target.parentNode.cellIndex+"-"+monat+"-"+jahr);
	}
}
*/

/*##### event listener mouseover/mouseout @ tooltip #####*/

//event delegation

function evSwitch(e) {
    var eventTyp = e.type;
    if (e.target && e.target.nodeName == 'SPAN') {
        switch (eventTyp) {
            case 'mouseover':
                tooltipData(e);
                break;
            case 'mouseout':
                tooltip.hide();
                break;
            case 'click':
                removerowmessage();
                pinid = e.target.id;
                console.log('PIN CLICKED ID --> ', pinid);
                openmessage(2, pinid);
                break;
            default:
        }
    }
}

/*##### message #####
    hier wird das Formularfeld generiert
 */
function openmessage(n, pinid) {

    var dataset = document.getElementById(pinid).dataset;
    var buttons = '';

    //console.log(dataset);

    switch (n) {
        case 1: //neuen termin setzen
            buttons =
                '<input type="button" class="msg" value="Termin speichern" onclick="termintafel.addpin(0)"><br /><br />' +
                '<input type="button" class="msg" value="Abbrechen" onclick="termintafel.abbrechen(id)">';
            break;
        case 2: //vorhandener termin bearbeiten
            buttons =
                '<input type="button" class="msg" value="Termin aktualisieren" onclick="termintafel.addpin(1)"><br /><br />' +
                '<input type="button" class="msg" value="Abbrechen" onclick="termintafel.abbrechen(id)">';
            break;

        default:
    }

    /* new message append row */

    var setani = document.getElementById(pinid);
    var pinidclass = setani.className + ' blink';
    setani.className = pinidclass;

    var rowid = document.getElementById(pinid).parentNode.parentNode.id;
    var row = document.getElementById(rowid);

    var cells = row.getElementsByTagName('td');
    var length = cells.length;

    var newrowTr = document.createElement('tr');
    newrowTr.setAttribute('class', 'fade-in');
    newrowTr.setAttribute('id', 'rowmessage');
    var newrowTh = document.createElement('th');
    newrowTr.appendChild(newrowTh);
    var newrowTd = document.createElement('td');

    const urlToNewTermintafel = `${environment.newTermintafelFrontendUrl}/customer-scheduling?is_call_from_legacy=true`;
    newrowTd.innerHTML = '<iframe ' +
        'src="' + urlToNewTermintafel + '" ' +
        'width="100%" ' +
        'height="400" ' +
        'name="Termintafel" style="border-width: 0;"> ' +
        '<p>Ihr Browser kann leider keine eingebetteten Frames anzeigen:<br/>' +
        'Sie können die eingebettete Seite über den folgenden Verweis aufrufen: <br/>' +
        '<a href="' + urlToNewTermintafel + '" target="_blank">' + urlToNewTermintafel + '</a>' +
        '</p>' +
        '</iframe>';

    newrowTd.setAttribute('colspan', length);
    newrowTr.appendChild(newrowTd);

    row.parentNode.insertBefore(newrowTr, row.nextSibling);

    if (typeof dataset.intvorname === 'undefined') {
    } else {
        document.getElementById('intvorname').value = dataset.intvorname;
        document.getElementById('intnachname').value = dataset.intnachname;
        document.getElementById('tausgefallen').value = dataset.tausgefallen;
        document.getElementById('tnotiz').value = dataset.tnotiz;
        document.getElementById('tumsatzschaetzung').value = dataset.tumsatzschaetzung;
    }
}

/*
function ausgefallen() {
    var element = document.getElementById(pinid);
    element.setAttribute('data-tausgefallen', 'ja');
    addpin();
}
*/

function abbrechen() {
    removerowmessage();
    var dataset = document.getElementById(pinid).dataset;
    if (typeof dataset.intvorname === 'undefined') {
        element = document.getElementById(pinid);
        element.parentNode.removeChild(element);
        console.log("PIN REMOVED ID --> ", pinid);
    } else {
        removepinanimation();
    }
}

function addpin(n) {

    if (document.getElementById("intvorname").value === "" || document.getElementById("intnachname").value === "") {
        if (document.getElementById("intvorname").value === "") {
            document.getElementById("intvorname").style.border = "1px solid #DD2C2C";
        } else {
            document.getElementById("intvorname").style.border = "";
        }
        if (document.getElementById("intnachname").value === "") {
            document.getElementById("intnachname").style.border = "1px solid #DD2C2C";
        } else {
            document.getElementById("intvorname").style.border = "";
        }
    } else {

        var element = document.getElementById(pinid);

        if (n == 1) {
            element.setAttribute('data-taktualisiert', 1);
        }

        element.setAttribute('data-intnachname', document.getElementById('intnachname').value);
        element.setAttribute('data-intvorname', document.getElementById('intvorname').value);
        element.setAttribute('data-tumsatzschaetzung', document.getElementById('tumsatzschaetzung').value);
        element.setAttribute('data-tnotiz', document.getElementById('tnotiz').value);

        element.setAttribute('data-tausgefallen', document.getElementById('tausgefallen').value);
        //var tausgefallen = document.getElementById(pinid).getAttribute('data-tausgefallen');
        //element.setAttribute('data-tausgefallen', tausgefallen);

        element.setAttribute('data-tid', pinid);
        var parentdate = element.parentNode.dataset;
        var date = parentdate.date;
        element.setAttribute('data-tdatum', date);
        // # admin
        var rowvppfad = element.parentNode.parentNode.id;
        element.setAttribute('data-vppfad', rowvppfad);
        //element.setAttribute('data-vppfad', vppfad);
        var dataset = document.getElementById(pinid).dataset;
        console.log("PIN SAVED   ID --> ", pinid);
        //console.log(dataset);
        jsonp(dataset);
        removerowmessage();
        countdata();
    }
}

function removepinanimation() {
    var element = document.getElementById(pinid);
    if (element != null) {
        var classname = "circle " + element.getAttribute('data-ttyp') + " access";
        element.className = classname;
        if (typeof element.getAttribute('data-intvorname') === 'undefined') {
            element.parentNode.removeChild(element);
        }
    }
    addEventListener();
}

function removerowmessage() {
    var element = document.getElementById('rowmessage');
    if (element == null) {
    } else {
        element.parentNode.removeChild(element);
        removepinanimation();
    }
}


/*##### d&d pin generator #####*/

var pinArray = [];
var pinCounter = 0;

function generatepins() {

    message = ["service", "tn", "t3", "t2", "t1", "service", "tn", "t3", "t2", "t1"];
    toepfe_id = ["topfo1", "topfo2", "topfo3", "topfo4", "topfo5", "topfu1", "topfu2", "topfu3", "topfu4", "topfu5"]
    toepfe_el = [];

    for (w = 0; w < toepfe_id.length; w++) {
        el = document.getElementById(toepfe_id[w]);
        el.innerHTML = '';
        toepfe_el.push(el);
    }

    toepfe = toepfe_el;

    for (k = 0; k < toepfe.length; k++) {

        cmyk = message[k];
        pinArray = [];
        pinArray.push([1, 1, 18, 1111]);

        for (i = 0; i < 4; i++) {

            UUIDv4 = generateId(32, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

            toepfe[k].innerHTML += '<span id=' + UUIDv4 + ' data-tTyp=' + cmyk +
                ' draggable="true" ondragstart="termintafel.drag(event)" ondragend="termintafel.dragend(event)" onmousedown="termintafel.setdragimg(this)" class="circle ' +
                cmyk + '"></span>';

            pin = document.getElementById(UUIDv4);
            pin.style.position = 'absolute';

            x = toepfe[k].offsetHeight - 20;
            y = toepfe[k].offsetWidth - 20;

            //console.log(x +'< x - y >'+ y);

            randomx = Math.floor(Math.random() * x + 1);
            randomy = Math.floor(Math.random() * y + 1);
            diaz = 18;

            while (overlapping(randomx + diaz / 2, // x midpoint
                randomy + diaz / 2, // y midpoint
                diaz / 2)) { // radius
                // generate again if overlapping any other image

                randomx = Math.floor(Math.random() * x + 1); //random top position
                randomy = Math.floor(Math.random() * y + 1); //random left position

            }

            pinCounter = 0;
            pinArray.push([randomx, randomy, diaz, UUIDv4]);

        }

        for (var r = 1; r < pinArray.length; r++) {

            document.getElementById(pinArray[r][3]).setAttribute('style', 'position:absolute; top:' + pinArray[r][0] +
                'px; left:' + pinArray[r][1] + 'px;');

        }
    }
    //console.log("[D&D Pins generiert]");
}

function overlapping(x, y, r) {

    for (var i = 0; i < pinArray.length; i++) { // iterate over all images
        var im = pinArray[i]; // this image

        var dx = im[0] - x; // x distance between this image and new image
        var dy = im[1] - y; // y distance between this image and new image

        if (Math.sqrt(dx * dx + dy * dy) <= im[2] + r) {
            // if distance between midpoints is less than the radii, they are overlapping

            pinCounter++;

            if (pinCounter == 25) {
                return false;
            } else {
                return true;
            }
        }
    }
    return false; // when we reach this point the new image has not been overlapping any
}

/*##### guid generator #####*/

function generateId(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
}

/*##### tooltip #####*/

function tooltipData(e) {
    var id = e.target.id;
    var data = document.getElementById(id);
    var date = document.getElementById(id).parentNode.dataset.date;

    //console.log(id," - ",data," - ",date);

    typ = "<div>Datum:</div><p>" + date +
        /*"</p><div>TerminArt:</div><p>" + data.dataset.ttyp +*/
        /*"</p><div>TerminID:</div><p>" + data.id +*/
        "</p><div>Kundenname:</div><p>" + data.dataset.intvorname +
        " " + data.dataset.intnachname +
        "</p><div>Umsatzschätzung:</div><p>" + data.dataset.tumsatzschaetzung +
        "</p><div>Notiz:</div><p>" + data.dataset.tnotiz +
        "</p>";
    tooltip.show(typ);
}

var tooltip = function () {
    var id = 'ttt';
    var top = 3;
    var left = 3;
    var maxw = 300;
    var speed = 10;
    var timer = 20;
    var endalpha = 95;
    var alpha = 0;
    var tt, t, c, b, h;
    var ie = document.all ? true : false;
    return {
        show: function (v, w) {
            if (tt == null) {
                tt = document.createElement('div');
                tt.setAttribute('id', id);
                t = document.createElement('div');
                t.setAttribute('id', id + 'top');
                c = document.createElement('div');
                c.setAttribute('id', id + 'cont');
                b = document.createElement('div');
                b.setAttribute('id', id + 'bot');
                tt.appendChild(t);
                tt.appendChild(c);
                tt.appendChild(b);
                document.body.appendChild(tt);
                tt.style.opacity = 0;
                tt.style.filter = 'alpha(opacity=0)';
                document.onmousemove = this.pos;
            }
            tt.style.display = 'block';
            c.innerHTML = v;
            c.className = "tooltip";
            tt.style.width = w ? w + 'px' : 'auto';
            if (!w && ie) {
                t.style.display = 'none';
                b.style.display = 'none';
                tt.style.width = tt.offsetWidth;
                t.style.display = 'block';
                b.style.display = 'block';
            }
            if (tt.offsetWidth > maxw) {
                tt.style.width = maxw + 'px'
            }
            h = parseInt(tt.offsetHeight) + top;
            clearInterval(tt.timer);
            tt.timer = setInterval(function () {
                tooltip.fade(1)
            }, timer);
        },
        pos: function (e) {
            var u = ie ? event.clientY + document.documentElement.scrollTop : e.pageY;
            var l = ie ? event.clientX + document.documentElement.scrollLeft : e.pageX;
            tt.style.top = (u - h) + 'px';
            tt.style.left = (l + left) + 'px';
        },
        fade: function (d) {
            var a = alpha;
            if ((a != endalpha && d == 1) || (a != 0 && d == -1)) {
                var i = speed;
                if (endalpha - a < speed && d == 1) {
                    i = endalpha - a;
                } else if (alpha < speed && d == -1) {
                    i = a;
                }
                alpha = a + (i * d);
                tt.style.opacity = alpha * .01;
                tt.style.filter = 'alpha(opacity=' + alpha + ')';
            } else {
                clearInterval(tt.timer);
                if (d == -1) {
                    tt.style.display = 'none'
                }
            }
        },
        hide: function () {
            if (tt !== undefined) {
                clearInterval(tt.timer);
                tt.timer = setInterval(function () {
                    tooltip.fade(-1)
                }, timer);
            }
        }
    };
}();

/*##### statistic #####*/

function countdata() {

    var table = document.getElementById("tt");
    var t1 = 0;
    var t3 = 0;
    var service = 0;
    var tn = 0;
    var t2 = 0;
    var vpa = 0;
    var teamumsatz = 0;
    var t1t = 0;
    var t3t = 0;
    var servicet = 0;
    var tnt = 0;
    var t2t = 0;
    var ta = 0;

    //var factor = table.getElementsByTagName("span");
    //console.log(factor.length);
    var ttotal = 0;
    var tmax = [];
    var factor = 0;

    for (var i = 0, tbody; tbody = table.tBodies[i]; i++) {
        if (tbody.getAttribute('class') == 'teamhead') {
            factor = 0;
            continue;
        }

        for (var j = 0, row; row = table.tBodies[i].rows[j]; j++) {
            var element = row.getElementsByTagName('span');
            factor = factor + element.length;
        }
        console.log(tbody.id, '-', factor);
        tmax.push(factor);

    }
    var val = Math.max.apply(Math, tmax);

    if (val <= 345) {
        factor = 2
    }
    if (val > 345 && val <= 690) {
        factor = 1
    }
    if (val > 690 && val <= 1380) {
        factor = 0.5
    }
    if (val > 1380 && val <= 2760) {
        factor = 0.25
    }

    console.log(Math.max.apply(Math, tmax), ' ', factor);

    for (var i = 0, tbody; tbody = table.tBodies[i]; i++) {
        if (tbody.getAttribute('class') == 'teamhead') {
        } else {
            for (var j = 0, row; row = table.tBodies[i].rows[j]; j++) {
                var element = row.getElementsByTagName('span');
                for (var k = 0; k < element.length; k++) {
                    var m = element[k].getAttribute('data-tumsatzschaetzung');
                    var mm = element[k].getAttribute('data-tausgefallen');

                    if (typeof (m) === 'string' && mm === 'nein') {
                        teamumsatz = teamumsatz + parseFloat(m);
                    }
                    if (mm === 'nein') {
                        n = element[k].getAttribute('data-ttyp');
                        switch (n) {
                            case "tn":
                                tn++;
                                tnt++;
                                break;
                            case "t3":
                                t3++;
                                t3t++;
                                break;
                            case "t1":
                                t1++;
                                t1t++;
                                break;
                            case "t2":
                                t2++;
                                t2t++;
                                break;
                            case "service":
                                service++;
                                servicet++;
                                break;
                            default:
                        }
                    } else {
                        vpa++;
                        ta++;
                    }

                }
                var vptotal = t1 + t3 + service + tn + t2;
                var teamid = tbody.getAttribute('id');
                var vpstatistic = 'statistics' + teamid + 'vp' + j;
                document.getElementById(vpstatistic).innerHTML =
                    '<li class="grey">' + t1 +
                    '</li> <li class="orange">' + t2 +
                    '</li> <li class="red">' + t3 +
                    '</li> <li class="blue">' + tn +
                    '</li> <li class="green">' + service +
                    '</li> <li class="black">' + vptotal +
                    '</li><li class="greya">-' + vpa +
                    '</li>';
                t1 = 0;
                t3 = 0;
                service = 0;
                tn = 0;
                t2 = 0;
                vpa = 0;
            }
            var teamtotal = t1t + t3t + servicet + tnt + t2t;
            var teamid = tbody.getAttribute('id');
            var teamstatistic = 'statistics' + teamid;
            document.getElementById(teamstatistic).innerHTML =
                '<li class="grey">' + t1t +
                '</li> <li class="orange">' + t2t +
                '</li> <li class="red">' + t3t +
                '</li> <li class="blue">' + tnt +
                '</li> <li class="green">' + servicet +
                '</li> <li class="black">' + teamtotal +
                '</li><li class="greya">-' + ta +
                '</li> <li class="grey"> | ' + teamumsatz.toFixed(2) +
                ' EUR</li>';

            var teamchart = 'charts' + teamid;
            if (ta > 0) {
                var seperator = '<li class="sep"></li>';
            } else {
                var seperator = '';
            }

            document.getElementById(teamchart).innerHTML =
                '<li class="greyc" style="width:' + t1t * factor +
                'px"></li><li class="orangec" style="width:' + t2t * factor +
                'px"></li><li class="redc" style="width:' + t3t * factor +
                'px"></li><li class="bluec" style="width:' + tnt * factor +
                'px"></li><li class="greenc" style="width:' + servicet * factor +
                'px"></li>' + seperator + '<li class="greyca" style="width:' + ta * factor +
                'px"></li>';

            t1t = 0;
            t3t = 0;
            servicet = 0;
            tnt = 0;
            t2t = 0;
            teamumsatz = 0;
            ta = 0;
        }
    }
}

/*##### show/hide statistics #####*/

function hidestatistics() {
    var className = ['statisticsvp', 'statisticsteam', 'vn', 'chartsteam'];
    for (var i = 0; i < className.length; i++) {
        var elements = document.getElementsByClassName(className[i]),
            n = elements.length;
        for (j = 0; j < n; j++) {
            var e = elements[j];
            if (e.style.display == '') {
                e.style.display = 'none';
            } else {
                e.style.display = '';
            }
        }
    }
}

/*##### show/hide team #####*/

function hideteam(obj) {
    var element1 = document.getElementById(obj.id);
    var element2 = document.getElementById('img' + obj.id);
    if (element1.style.display != 'none') {
        element1.style.display = 'none';
        element1.setAttribute('class', '');
        element2.src = 'images/max.png';
    } else {
        element1.style.display = '';
        element1.setAttribute('class', 'fade-in');
        element2.src = 'images/min.png';
    }
}

/*##### d&d pin contrast on/off #####*/

function contrastOnOff(n) {

    var table = document.getElementById('tt');
    var numRows = table.rows.length;

    for (var i = 0; i < numRows; i++) {
        if (rolle == 'TL' || rolle == 'VM') continue;
        var cells = table.rows[i].getElementsByTagName('span');
        //var rowindex = table.rows[i].rowIndex;

        for (var ic = 0, it = cells.length; ic < it; ic++) {
            if (table.rows[i].id == vppfad) {
            } else {
                switch (n) {
                    case 1:

                        cells[ic].setAttribute('class', "circle small t1");
                        cells[ic].setAttribute('style', "opacity:0.5");
                        break;
                    case 2:
                        var ttyp = cells[ic].getAttribute('data-tTyp')
                        cells[ic].setAttribute('class', 'circle small ' + ttyp);
                        cells[ic].setAttribute('style', "");
                        break;
                    default:
                    //never
                }
            }
        }
    }
}

/*##### drag n drop n move  #####*/

function allowDrop(ev) {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = 'copy';
}

function drag(ev) {

    var element = document.getElementById(pinid);

    if (pinid != null) {
        if (document.getElementById(pinid) != null) {
            if (element.getAttribute('data-intvorname') === null) {
                //console.log(pinid," - ",typeof element.getAttribute('data-intvorname'));
                console.log("PIN REMOVED ID --> ", pinid);
                element.parentNode.removeChild(element);
            }
        }
    }

    ev.dataTransfer.setDragImage(dragIcon, 5, 35);
    ev.dataTransfer.effectAllowed = 'copy';
    ev.dataTransfer.setData('Text', ev.target.id);
    console.log("PIN DRAGGED ID --> ", ev.target.id);
    contrastOnOff(1);
    removerowmessage();
}

function dragend(ev) {
    contrastOnOff(2);
    console.log("PIN RELEASE ID --> ", ev.target.id);
}

function setdragimg(ev) {
    dragIcon = document.createElement('img');
    var id = ev.id;
    document.getElementById(id).style.cursor = '-moz-grabbing';
    var ttyp = document.getElementById(id).dataset.ttyp;
    dragIcon.src = 'images/pin_' + ttyp + '.png';
    dragIcon.width = 12;
}

function dragenter(ev) {
    if (ev.target.nodeName == "TD") {
        ev.target.style.background = "#E3FFE3";
    }
}

function dragleave(ev) {
    if (ev.target.nodeName == "TD") {
        ev.target.removeAttribute("style");
    }
}

function drop(ev) {

    ev.preventDefault();
    //ev.target.style.background = "";
    ev.target.removeAttribute("style");
    pinid = ev.dataTransfer.getData('Text', ev.target.id);

    var droppedPin = document.getElementById(pinid);

    droppedPin.removeAttribute('draggable');
    droppedPin.removeAttribute('ondragstart');
    droppedPin.removeAttribute('style');
    droppedPin.removeAttribute('onmousedown');
    droppedPin.removeAttribute('ondragend');

    var ttyp = document.getElementById(pinid).getAttribute('data-ttyp');

    droppedPin.setAttribute("data-tausgefallen", "nein");
    droppedPin.setAttribute('class', 'circle ' + ttyp + ' access');
    //droppedPin.setAttribute("onmouseover", "tooltipData(event);");
    //droppedPin.setAttribute("onmouseout", "tooltip.hide();");
    //droppedPin.addEventListener('mouseover', function(event) {tooltipData(event)}, false);
    //droppedPin.addEventListener('mouseout', function() {tooltip.hide()}, false);

    if (ev.target.nodeName == 'SPAN') {
        ev.target.parentNode.appendChild(document.getElementById(pinid));
        //console.log("in [ " + ev.target.nodeName + " ]gedropped");

    } else {
        ev.target.appendChild(document.getElementById(pinid));
        //console.log("in [ " + ev.target.nodeName + " ]gedropped");
    }

    contrastOnOff(2);
    removeEventListener();
    console.log("PIN DROPPED ID --> ", pinid);
    openmessage(1, pinid);

    /* groeßer 6 elemente > kein drop mehr
    elements = ev.target.getElementsByTagName('span');
    elementsCount = elements.length;
    console.log(elements.length);*/
}

module.exports = {
    init,
    jsonp,
    pfad,
    datum,
    datum2,
    buero,
    termine,
    adminYesNo,
    setdragimg,
    hideteam,
    abbrechen,
    addpin,
    hidestatistics,
    datumaendern,
    switchview,
    drag,
    dragend,
    termineintragen
}
