module.exports = {
  production: true,
  keycloak: {
    url: 'KEYCLOAK_HOST',
    realm: 'KEYCLOAK_REALM',
    clientId: 'ci_termintafel_frontend',
    principalAttribute: 'email'
  },
  newTermintafelFrontendUrl: 'NEW_TERMINTAFEL_FRONTEND_URL'
};
